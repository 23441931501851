<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">The Riptide Rush flavor of Gatorade has the following absorption spectrum:</p>
      <p class="mb-4">
        <v-img style="max-width: 500px" src="/img/assignments/gatoradeGraph.png" />
      </p>

      <p class="mb-2">
        What color dyes are present in this Gatorade?
        <v-select
          v-model="inputs.input1"
          style="max-width: 250px"
          :items="items1"
          item-text="text"
          item-value="value"
          label="a"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-2">
        Does each dye have it's own
        <stemble-latex content="$\lambda_{\text{max}}?$" />
        <v-select
          v-model="inputs.input2"
          style="max-width: 250px"
          :items="items2"
          item-text="text"
          item-value="value"
          label="b"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-2">
        If your Beer's Law Plot was for the red dye, at what wavelength would you take the
        absorbance to determine the concentration of red dye in this flavor of Gatorade?
        <v-select
          v-model="inputs.input3"
          style="max-width: 250px"
          :items="items3"
          item-text="text"
          item-value="value"
          label="c"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '../StembleLatex.vue';

export default {
  name: 'ChemUCI1LCQ2S2Q5',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
      },
      items1: [
        {text: 'Blue', value: 'blue'},
        {text: 'Red', value: 'red'},
        {text: 'Yellow', value: 'yellow'},
        {text: 'Two of these', value: 'two'},
        {text: 'Three of these', value: 'three'},
      ],
      items2: [
        {text: 'Yes', value: 'yes'},
        {text: 'No', value: 'no'},
        {text: 'If they have equal absorbance values they do', value: 'equal'},
      ],
      items3: [
        {text: '$390\\,\\text{nm}$', value: 'threeNinety'},
        {text: '$500\\,\\text{nm}$', value: 'fiveHundred'},
        {text: '$630\\,\\text{nm}$', value: 'sixThirty'},
        {text: 'Two of these', value: 'two'},
        {text: 'All of these', value: 'all'},
        {text: 'None of these', value: 'none'},
      ],
    };
  },
};
</script>
<style scoped></style>
